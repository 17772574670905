// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: null,
  selected: null,
  loading: false,
  error: null,
  success: false,
  barcode: null,
  add_pemotongan: null,
  pdf_permintaan: null,
  spk: null,
  pdf_spk: null,
  dataModal: [],
  totalModal: 1,
  paramsModal: null,
  add_pengiriman: null
}

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_PENGIRIMAN_KAIN':
      return { ...state, allData: action.data }
    case 'GET_DATA_PENGIRIMAN_KAIN':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DATA_PENGIRIMAN_KAIN_MODAL':
      return {
        ...state,
        dataModal: action.data,
        totalModal: action.totalPages,
        paramsModal: action.params
      }
    case 'GET_PENGIRIMAN_KAIN':
      return { ...state, selected: action.selected }
    case 'GET_PENGIRIMAN_KAIN_BARCODE':
      return { ...state, barcode: action.barcode }
    case 'GET_PERMINTAAN_KAIN_PDF':
      return { ...state, pdf_permintaan: action.pdf_permintaan }
    case 'GET_SP_KAIN_PDF':
      return { ...state, pdf_spk: action.pdf_spk }
    case 'ADD_PEMOTONGAN_KAIN':
      return { ...state, add_pemotongan: action.value  }
    case 'ADD_SP_KAIN':
      return { ...state, spk: action.data  }
    case 'ADD_PENGIRIMAN_KAIN':
      return { ...state, add_pengiriman: action.value }
    case 'DELETE_PENGIRIMAN_KAIN':
      return { ...state }
    case 'RESET_PENGIRIMAN_KAIN':
      return {
        ...state,
        loading: false,
        error: null,
        success: false
      }
    case 'REQUEST_PENGIRIMAN_KAIN':
      return {
        ...state,
        loading: true
      }
    case 'SUCCESS_PENGIRIMAN_KAIN':
      return {
        ...state,
        loading: false,
        success: true
      }
    case 'ERROR_PENGIRIMAN_KAIN':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return { ...state }
  }
}
export default reducers

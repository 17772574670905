// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: null,
  selected: null,
  loading: false,
  error: null,
  success: false,
  pdf: null,
  barcode: null,
  tmp: [],
  values: null,
  add_tmp: null,
  lot_data: null,
  scan_data: null,
  file: null
}

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_STOCK_AWAL_KAIN':
      return { ...state, allData: action.data }
    case 'GET_DATA_STOCK_AWAL_KAIN':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_STOCK_AWAL_KAIN':
      return { ...state, selected: action.selected }
    case 'GET_STOCK_AWAL_KAIN_PDF':
      return { ...state, pdf: action.pdf }
    case 'GET_STOCK_AWAL_KAIN_BARCODE':
      return { ...state, barcode: action.barcode }
    case 'GET_STOCK_AWAL_KAIN_TMP':
      return { ...state, tmp: action.tmp, values: action.values }
    case 'ADD_STOCK_AWAL_KAIN_TMP':
      return { ...state, add_tmp: action.add_tmp }
    case 'GET_STOCK_AWAL_KAIN_CHECK_LOT':
      return { ...state, lot_data: action.lot_data }
    case 'GET_STOCK_AWAL_KAIN_BY_BARCODE':
      return { ...state, scan_data: action.scan_data }
    case 'ADD_STOCK_AWAL_KAIN':
      return { ...state }
    case 'DELETE_STOCK_AWAL_KAIN':
      return { ...state }
    case 'UPLOAD_STOCK_AWAL_KAIN':
      return { ...state, loading: true }
    case 'DOWNLOAD_TEMPLATE_STOCK_AWAL_KAIN':
      return { ...state, file: action.file }
    case 'RESET_STOCK_AWAL_KAIN':
      return {
        ...state,
        loading: false,
        error: null,
        success: false
      }
    case 'REQUEST_STOCK_AWAL_KAIN':
      return {
        ...state,
        loading: true
      }
    case 'SUCCESS_STOCK_AWAL_KAIN':
      return {
        ...state,
        loading: false,
        success: true
      }
    case 'ERROR_STOCK_AWAL_KAIN':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return { ...state }
  }
}
export default reducers

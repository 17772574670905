// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import scales from './scale'

// ** navigation
import navigations from '@src/navigation/store/reducer'
// ** profile
import profile from '@src/views/backend/auth/profile/store/reducer'
// ** management
import users from '@src/views/backend/user/store/reducer'
import roles from '@src/views/backend/role/store/reducer'
import menus from '@src/views/backend/menu/store/reducer'
import rolemenus from '@src/views/backend/role_menu/store/reducer'
import globalparams from '@src/views/backend/global_param/store/reducer'
import perusahaans from '@src/views/backend/perusahaan/store/reducer'
import sites from '@src/views/backend/site/store/reducer'
import statusppns from '@src/views/backend/status_ppn/store/reducer'
// ** master
import jenisbenangs from '@src/views/backend/jenis_benang/store/reducer'
import ukuranbenangs from '@src/views/backend/ukuran_benang/store/reducer'
import settingans from '@src/views/backend/settingan/store/reducer'
import ketebalans from '@src/views/backend/ketebalan/store/reducer'
import kategoriwarnas from '@src/views/backend/kategori_warna/store/reducer'
import warnas from '@src/views/backend/warna/store/reducer'
import kains from '@src/views/backend/kain/store/reducer'
import kategoris from '@src/views/backend/kategori/store/reducer'
import satuans from '@src/views/backend/satuan/store/reducer'
import mereks from '@src/views/backend/merek/store/reducer'
import suppliers from '@src/views/backend/supplier/store/reducer'
import barangs from '@src/views/backend/barang/store/reducer'
import banks from '@src/views/backend/bank/store/reducer'
import currencys from '@src/views/backend/currency/store/reducer'
import paymentmethods from '@src/views/backend/payment_method/store/reducer'
import paymentterms from '@src/views/backend/payment_term/store/reducer'
import gudangs from '@src/views/backend/gudang/store/reducer'
import raks from '@src/views/backend/rak/store/reducer'
import raknews from '@src/views/backend/raks/store/reducer'
import partbarangs from '@src/views/backend/part_barang/store/reducer'
import tipeproduksis from '@src/views/backend/tipe_produksi/store/reducer'
import tujuans from '@src/views/backend/tujuan/store/reducer'
import bankperusahaans from '@src/views/backend/bank_perusahaan/store/reducer'
import banksuppliers from '@src/views/backend/bank_supplier/store/reducer'
import artikels from '@src/views/backend/artikel/store/reducer'
import customers from '@src/views/backend/customer/store/reducer'
import lorongs from '@src/views/backend/lorong/store/reducer'
import namakains from '@src/views/backend/nama_kain/store/reducer'
import tipekains from '@src/views/backend/tipe_kain/store/reducer'
// ** transaction pembelian
import permintaanbarangs from '@src/views/backend/permintaan_barang/store/reducer'
import preorderbarangs from '@src/views/backend/pre_order_barang/store/reducer'
import purchaseorderbarangs from '@src/views/backend/purchase_order_barang/store/reducer'
import penerimaanbarangs from '@src/views/backend/penerimaan_barang/store/reducer'
import checkpenerimaanbarangs from '@src/views/backend/check_penerimaan_barang/store/reducer'
import qcpembelianbarangs from '@src/views/backend/qc_pembelian_barang/store/reducer'
import hasilqcbarangs from '@src/views/backend/hasil_qc_barang/store/reducer'
import fakturpembelianbarangs from '@src/views/backend/faktur_pembelian_barang/store/reducer'
import returpembelianbarangs from '@src/views/backend/retur_pembelian_barang/store/reducer'
import notareturpembelianbarangs from '@src/views/backend/nota_retur_pembelian_barang/store/reducer'
import ongkirpembelianbarangs from '@src/views/backend/ongkir_pembelian_barang/store/reducer'
import suratjalanreturpembelianbarangs from '@src/views/backend/surat_jalan_retur_pembelian_barang/store/reducer'
import pemakaianbarangs from '@src/views/backend/pemakaian_barang/store/reducer'
import permintaankains from '@src/views/backend/permintaan_kain/store/reducer'
import preorderkains from '@src/views/backend/pre_order_kain/store/reducer'
import purchaseorderkains from '@src/views/backend/purchase_order_kain/store/reducer'
import penerimaankains from '@src/views/backend/penerimaan_kain/store/reducer'
import checkpenerimaankains from '@src/views/backend/check_penerimaan_kain/store/reducer'
import fakturpembeliankains from '@src/views/backend/faktur_pembelian_kain/store/reducer'
import returpembeliankains from '@src/views/backend/retur_pembelian_kain/store/reducer'
import qcpembeliankains from '@src/views/backend/qc_pembelian_kain/store/reducer'
import hasilqckains from '@src/views/backend/hasil_qc_kain/store/reducer'
import notareturpembeliankains from '@src/views/backend/nota_retur_pembelian_kain/store/reducer'
import ongkirpembeliankains from '@src/views/backend/ongkir_pembelian_kain/store/reducer'
import suratjalanreturpembeliankains from '@src/views/backend/surat_jalan_retur_pembelian_kain/store/reducer'
import permintaanjasas from '@src/views/backend/permintaan_jasa/store/reducer'
import preorderjasas from '@src/views/backend/pre_order_jasa/store/reducer'
import purchaseorderjasas from '@src/views/backend/purchase_order_jasa/store/reducer'
import penerimaanjasas from '@src/views/backend/penerimaan_jasa/store/reducer'
import fakturpembelianjasas from '@src/views/backend/faktur_pembelian_jasa/store/reducer'
import returpembelianjasas from '@src/views/backend/retur_pembelian_jasa/store/reducer'
import notareturpembelianjasas from '@src/views/backend/nota_retur_pembelian_jasa/store/reducer'
import suratjalankainmaklons from '@src/views/backend/surat_jalan_kain_maklon/store/reducer'
import penerimaankainmaklons from '@src/views/backend/penerimaan_kain_maklon/store/reducer'
// ** transaction penjualan
import presalesbarangs from '@src/views/backend/pre_sales_barang/store/reducer'
import salesorderbarangs from '@src/views/backend/sales_order_barang/store/reducer'
import terimaorderbarangs from '@src/views/backend/terima_order_barang/store/reducer'
import salescontractbarangs from '@src/views/backend/sales_contract_barang/store/reducer'
import pengirimanbarangs from '@src/views/backend/pengiriman_barang/store/reducer'
import fakturpenjualanbarangs from '@src/views/backend/faktur_penjualan_barang/store/reducer'
import returpenjualanbarangs from '@src/views/backend/retur_penjualan_barang/store/reducer'
import notareturpenjualanbarangs from '@src/views/backend/nota_retur_penjualan_barang/store/reducer'
import presaleskains from '@src/views/backend/pre_sales_kain/store/reducer'
import salesorderkains from '@src/views/backend/sales_order_kain/store/reducer'
import terimaorderkains from '@src/views/backend/terima_order_kain/store/reducer'
import salescontractkains from '@src/views/backend/sales_contract_kain/store/reducer'
import pengirimankains from '@src/views/backend/pengiriman_kain/store/reducer'
import suratjalankains from '@src/views/backend/surat_jalan_kain/store/reducer'
import fakturpenjualankains from '@src/views/backend/faktur_penjualan_kain/store/reducer'
import requestreturpenjualankains from '@src/views/backend/request_retur_penjualan_kain/store/reducer'
import returpenjualankains from '@src/views/backend/retur_penjualan_kain/store/reducer'
import notareturpenjualankains from '@src/views/backend/nota_retur_penjualan_kain/store/reducer'
import salesorderjasas from '@src/views/backend/sales_order_jasa/store/reducer'
import terimaorderjasas from '@src/views/backend/terima_order_jasa/store/reducer'
import presalesjasas from '@src/views/backend/pre_sales_jasa/store/reducer'
import salescontractjasas from '@src/views/backend/sales_contract_jasa/store/reducer'
import pengirimanjasas from '@src/views/backend/pengiriman_jasa/store/reducer'
import fakturpenjualanjasas from '@src/views/backend/faktur_penjualan_jasa/store/reducer'
import returpenjualanjasas from '@src/views/backend/retur_penjualan_jasa/store/reducer'
import notareturpenjualanjasas from '@src/views/backend/nota_retur_penjualan_jasa/store/reducer'
import terimaorderolshops from '@src/views/backend/terima_order_olshop/store/reducer'
import fakturpenjualanolshops from '@src/views/backend/faktur_penjualan_olshop/store/reducer'

// ** inventory
import stockopnamebarangs from '@src/views/backend/stock_opname_barang/store/reducer'
import stockopnamekains from '@src/views/backend/stock_opname_kain/store/reducer'
import mutasibarangs from '@src/views/backend/mutasi_barang/store/reducer'
import mutasikains from '@src/views/backend/mutasi_kain/store/reducer'
import stockkains from '@src/views/backend/stock_kain/store/reducer'
import stockbarangs from '@src/views/backend/stock_barang/store/reducer'
import stockproduksis from '@src/views/backend/stock_produksi/store/reducer'
import stockawalkains from '@src/views/backend/stock_awal_kain/store/reducer'
import opnamekains from '@src/views/backend/opname_kain/store/reducer'
import requestopnamekains from '@src/views/backend/request_opname_kain/store/reducer'
import adjustmentopnamekains from '@src/views/backend/adjustment_opname_kain/store/reducer'

// ** accounting
import kataccounts from '@src/views/backend/kat_account/store/reducer'
import accounts from '@src/views/backend/account/store/reducer'
import jurnals from '@src/views/backend/jurnal/store/reducer'
import jurnalmappings from '@src/views/backend/jurnal_mapping/store/reducer'
import pembayaranhutangs from '@src/views/backend/pembayaran_hutang/store/reducer'
import pembayaranpiutangs from '@src/views/backend/pembayaran_piutang/store/reducer'
import jurnalclosings from '@src/views/backend/jurnal_closing/store/reducer'
import saldoawalperiodes from '@src/views/backend/saldo_awal_periode/store/reducer'

// ** report pembelian
import reportpurchasetotals from '@src/views/backend/report_purchase_total/store/reducer'
import reportpurchasedetails from '@src/views/backend/report_purchase_detail/store/reducer'
import reportpurchasehutangs from '@src/views/backend/report_purchase_hutang/store/reducer'
import pokuncompletes from '@src/views/backend/purchase_order_kain_uncomplete/store/reducer'
import reportreturpurchases from '@src/views/backend/report_retur_purchase/store/reducer'
import penerimaankainqcs from '@src/views/backend/penerimaan_kain_qc/store/reducer'
import notyetqcs from '@src/views/backend/not_yet_qc/store/reducer'
// ** report penjualan
import reportsalestotals from '@src/views/backend/report_sales_total/store/reducer'
import reportsalesdetails from '@src/views/backend/report_sales_detail/store/reducer'
import reportsalespiutangs from '@src/views/backend/report_sales_piutang/store/reducer'
import sckuncompletes from '@src/views/backend/sales_contract_kain_uncomplete/store/reducer'
import spkuncompletes from '@src/views/backend/surat_jalan_kain_uncomplete/store/reducer'
import transitoutkainuncompletes from '@src/views/backend/transit_out_kain_uncomplete/store/reducer'
import reportretursales from '@src/views/backend/report_retur_sales/store/reducer'
import presalenotcompletes from '@src/views/backend/presk_not_complete/store/reducer'
// ** report kain
import reportstockcardkains from '@src/views/backend/report_stock_card_kain/store/reducer'
import stockkaintransitins from '@src/views/backend/stock_kain_transit_in/store/reducer'
// ** report barang
import reportstockcardbarangs from '@src/views/backend/report_stock_card_barang/store/reducer'
// ** report produksi
import reportstockcardprods from '@src/views/backend/report_stock_card_prod/store/reducer'
// ** report keuangan
import reportjurnals from '@src/views/backend/report_jurnal/store/reducer'
import reportbukubesars from '@src/views/backend/report_buku_besar/store/reducer'
import reportneracas from '@src/views/backend/report_neraca/store/reducer'
import reportlabarugis from '@src/views/backend/report_laba_rugi/store/reducer'

// ** produksi
import artikelproduksis from '@src/views/backend/artikel_produksi/store/reducer'
import aksesoriss from '@src/views/backend/aksesoris/store/reducer'
import stylenos from '@src/views/backend/style_no/store/reducer'
import tasks from '@src/views/backend/task/store/reducer'
import finisheds from '@src/views/backend/finished/store/reducer'
import finishedsizes from '@src/views/backend/finished_size/store/reducer'
import pobuyers from '@src/views/backend/po_buyer/store/reducer'
import fullorderbuyers from '@src/views/backend/full_order_buyer/store/reducer'
import suratperintahkerjas from '@src/views/backend/surat_perintah_kerja/store/reducer'
import joborders from '@src/views/backend/job_order/store/reducer'
import spbkains from '@src/views/backend/spb_kain/store/reducer'
import spbbarangs from '@src/views/backend/spb_barang/store/reducer'
import vendors from '@src/views/backend/vendor/store/reducer'
import poproduksis from '@src/views/backend/po_produksi/store/reducer'
import pemakaiankainjadis from '@src/views/backend/pemakaian_kain_jadi/store/reducer'
import pemakaianbarangjadis from '@src/views/backend/pemakaian_barang_jadi/store/reducer'
import suratjalanproduksis from '@src/views/backend/surat_jalan_produksi/store/reducer'
import buktipenerimaanbarangs from '@src/views/backend/bukti_penerimaan_barang/store/reducer'
import qcbuktipenerimaanbarangs from '@src/views/backend/qc_bukti_penerimaan_barang/store/reducer'
import partbarangproduksis from '@src/views/backend/part_barang_produksi/store/reducer'

const rootReducer = combineReducers({
  navigations,
  auth,
  profile,
  users,
  navbar,
  layout,
  scales,
  roles,
  menus,
  rolemenus,
  globalparams,
  perusahaans,
  sites,
  statusppns,
  jenisbenangs,
  ukuranbenangs,
  settingans,
  ketebalans,
  kategoriwarnas,
  warnas,
  kains,
  kategoris,
  satuans,
  mereks,
  suppliers,
  barangs,
  banks,
  currencys,
  paymentmethods,
  paymentterms,
  gudangs,
  raks,
  raknews,
  partbarangs,
  tipeproduksis,
  tujuans,
  bankperusahaans,
  banksuppliers,
  artikels,
  customers,
  lorongs,
  namakains,
  permintaanbarangs,
  preorderbarangs,
  purchaseorderbarangs,
  checkpenerimaanbarangs,
  qcpembelianbarangs,
  hasilqcbarangs,
  presalesbarangs,
  salesorderbarangs,
  terimaorderbarangs,
  salescontractbarangs,
  penerimaanbarangs,
  fakturpembelianbarangs,
  returpembelianbarangs,
  notareturpembelianbarangs,
  ongkirpembelianbarangs,
  suratjalanreturpembelianbarangs,
  pemakaianbarangs,
  pengirimanbarangs,
  fakturpenjualanbarangs,
  returpenjualanbarangs,
  notareturpenjualanbarangs,
  permintaankains,
  preorderkains,
  purchaseorderkains,
  checkpenerimaankains,
  penerimaankains,
  fakturpembeliankains,
  ongkirpembeliankains,
  suratjalanreturpembeliankains,
  returpembeliankains,
  qcpembeliankains,
  hasilqckains,
  notareturpembeliankains,
  presaleskains,
  salesorderkains,
  terimaorderkains,
  salescontractkains,
  pengirimankains,
  suratjalankains,
  fakturpenjualankains,
  requestreturpenjualankains,
  returpenjualankains,
  notareturpenjualankains,
  permintaanjasas,
  preorderjasas,
  purchaseorderjasas,
  penerimaanjasas,
  fakturpembelianjasas,
  returpembelianjasas,
  notareturpembelianjasas,
  suratjalankainmaklons,
  penerimaankainmaklons,
  salesorderjasas,
  terimaorderjasas,
  presalesjasas,
  salescontractjasas,
  pengirimanjasas,
  fakturpenjualanjasas,
  returpenjualanjasas,
  notareturpenjualanjasas,
  stockopnamebarangs,
  stockopnamekains,
  mutasibarangs,
  mutasikains,
  terimaorderolshops,
  fakturpenjualanolshops,
  kataccounts,
  accounts,
  jurnals,
  jurnalmappings,
  stockkains,
  stockbarangs,
  tipekains,
  stockawalkains,
  opnamekains,
  requestopnamekains,
  adjustmentopnamekains,
  reportpurchasetotals,
  reportpurchasedetails,
  reportpurchasehutangs,
  pembayaranhutangs,
  pembayaranpiutangs,
  reportsalestotals,
  reportsalesdetails,
  reportsalespiutangs,
  reportstockcardkains,
  reportjurnals,
  jurnalclosings,
  reportbukubesars,
  reportneracas,
  reportlabarugis,
  saldoawalperiodes,
  stockkaintransitins,
  pokuncompletes,
  sckuncompletes,
  spkuncompletes,
  transitoutkainuncompletes,
  reportreturpurchases,
  reportretursales,
  presalenotcompletes,
  penerimaankainqcs,
  notyetqcs,
  artikelproduksis,
  aksesoriss,
  stylenos,
  tasks,
  finisheds,
  finishedsizes,
  pobuyers,
  fullorderbuyers,
  suratperintahkerjas,
  joborders,
  spbkains,
  spbbarangs,
  vendors,
  poproduksis,
  pemakaiankainjadis,
  pemakaianbarangjadis,
  suratjalanproduksis,
  buktipenerimaanbarangs,
  qcbuktipenerimaanbarangs,
  reportstockcardbarangs,
  reportstockcardprods,
  partbarangproduksis,
  stockproduksis
})

export default rootReducer

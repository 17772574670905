// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: null,
  selected: null,
  loading: false,
  error: null,
  success: false,
  table: [],
  mapping: [],
  values: null
}

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_RAK':
      return { ...state, allData: action.data, params: action.params }
    case 'GET_DATA_RAK':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_RAK':
      return { ...state, selected: action.selected }
    case 'TABLE_RAK':
      return { ...state, table: action.table }
    case 'MAPPING_RAK':
      return { 
        ...state, 
        mapping: action.mapping,
        values: action.values
      }
    case 'ADD_RAK':
      return { ...state }
    case 'DELETE_RAK':
      return { ...state }
    case 'RESET_RAK':
      return {
        ...state,
        loading: false,
        error: null,
        success: false
      }
    case 'REQUEST_RAK':
      return {
        ...state,
        loading: true
      }
    case 'SUCCESS_RAK':
      return {
        ...state,
        loading: false,
        success: true
      }
    case 'ERROR_RAK':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return { ...state }
  }
}
export default reducers

// ** Initial State
const initialState = {
    weight: 0
  }
  
  const scales = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_DATA_SCALE':
        return { ...state, weight: action.weight }
      default:
        return { ...state }
    }
  }

  export default scales
  